body {
  margin: 0;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ant-switch-checked {
  background-color: #a0905d !important;
}

#sider .ant-layout-sider-trigger {
  background-color: transparent;
  color: #a0905d;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal)
  .ant-menu-item-selected.menuitem {
  background-color: #ededed;
}

@media only screen and (max-width: 800px) {
  .ant-card-extra {
    margin-left: 0px;
    width: 100%;
  }
}

.elevated {
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.24);
  border-radius: 8px !important;
}
.ant-select,
.ant-table {
  border-radius: 8px;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 8px !important;
}

.ant-select-show-search.ant-select-multiple .ant-select-selector {
  border-radius: 8px !important;
}

.ant-btn {
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.24) !important;
}

#event-collapse .ant-collapse-content {
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.24);
  border-radius: 8px !important;
}

.inlineInput {
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.24);
}

.ant-pagination-item-active {
  border-color: #a0905d !important;
}
.ant-pagination-item-active a {
  color: #a0905d !important;
}
